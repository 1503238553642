<template>
  <div>
    <div style="width:100%;height:280px" id="kpiBottomChartReport"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { getStorage } from '@/utils/storage'
import { countContractAmountGb } from '@/api/dw/datav'
import { listUserContractAmountGroupByDate } from '@/api/dw/kpi'

export default {
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      dataSourcePie: null
    }
  },
  mounted () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      if (this.gbType === '2') {
        this.loadSalerData()
      } else {
        this.loadIndustryData()
      }
    },
    loadIndustryData () { // 载入行业数据
      const queryModel = {
        startDate: this.searchSchedule.startDate,
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        companyId: this.companyId,
        gbType: 'month'
      }

      countContractAmountGb(queryModel).then(res => {
        const showTitle = '项目总营收趋势对比（单位：万元）'
        const legends = ['签约额']
        const xLabels = res.map(x => x.id)
        const ySeries = [{
          name: '签约额',
          type: 'line',
          smooth: true,
          label: {
            show: true,
            position: 'top',
            valueAnimation: true,
            color: this.reportFontColor,
            rich: {
              color: this.reportFontColor
            }
          },
          animationDuration: 2000,
          data: res.map(x => (x.value * 0.0001).toFixed(2))
        }]

        this.initReportChartByGbType(showTitle, legends, xLabels, ySeries)
      })
    },
    loadSalerData () { // 载入销售数据
      const queryModel = {
        startDate: this.searchSchedule.startDate,
        endDate: this.searchSchedule.endDate,
        publisherId: this.publisherId,
        companyId: this.companyId,
        userId: this.gbId
      }
      listUserContractAmountGroupByDate(queryModel).then(res => {
        const showTitle = '销售额个人与公司销售额对比（单位：万元）'
        const legends = []
        const xLabels = []
        const ySeries = []

        res.forEach(element => {
          if (!legends.includes(element.s)) {
            legends.push(element.s)
          }

          if (!xLabels.includes(element.x)) {
            xLabels.push(element.x)
          }
        })

        legends.forEach(element => {
          ySeries.push({
            name: element,
            type: 'line',
            smooth: true,
            label: {
              show: true,
              position: 'top',
              valueAnimation: true,
              color: this.reportFontColor,
              rich: {
                color: this.reportFontColor
              }
            },
            animationDuration: 2000,
            data: res.filter(x => x.s === element).map(x => (x.y * 0.0001).toFixed(2))
          })
        })

        this.initReportChartByGbType(showTitle, legends, xLabels, ySeries)
      })
    },
    initReportChartByGbType (showTitle, legends, xLabels, ySeries) {
      if (this.dataSourcePie != null && this.dataSourcePie !== '' && this.dataSourcePie !== undefined) {
        this.dataSourcePie.dispose()
      }

      this.$nextTick(() => {
        // 防止页面被切换，元素被移除
        if (document.getElementById('kpiBottomChartReport')) {
          this.dataSourcePie = echarts.init(document.getElementById('kpiBottomChartReport'))
          const option = {
            title: {
              text: showTitle,
              textStyle: {
                color: this.reportFontColor === '#fff' ? '#dcdee2' : '#515a6e'
              }
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            legend: {
              data: legends,
              right: 50,
              textStyle: {
                color: this.reportFontColor
              }
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: 10,
              containLabel: true
            },
            xAxis: {
              type: 'category',
              boundaryGap: false,
              data: xLabels,
              axisLabel: {
                color: '#c5c8ce'
              }
            },
            yAxis: {
              type: 'value',
              splitLine: {
                show: false,
                lineStyle: {
                  opacity: 0.1
                }
              },
              axisLine: {
                show: true
              }
            },
            series: ySeries
          }

          this.dataSourcePie.setOption(option, true)
          const that = this
          window.addEventListener('resize', function () {
            that.dataSourcePie.resize()
          })
        }
      })
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    companyId () {
      return this.$store.state.situationWeb.companyId
    },
    gbId () {
      return this.$store.state.situationWeb.gbId
    },
    gbType () {
      return this.$store.state.situationWeb.gbType
    },
    reportFontColor () {
      // 获取并设置当前页面的主题
      const theme = getStorage('oohforce-workbench-theme') || 'dark'
      return theme === 'dark' ? '#fff' : '#000'
    }
  },
  watch: {
    searchSchedule: {
      deep: true,
      handler (newValue) {
        this.initPageData()
      }
    },
    companyId () {
      this.initPageData()
    },
    gbType () {
      this.initPageData()
    },
    gbId () {
      if (this.gbType === '2') { // 只允许在销售变更后，才触发
        this.initPageData()
      }
    }
  }
}
</script>

<style>

</style>
